<template>
  <main-body-section class="tension-page">
    <template #map>
      <div id="map" class="h-100 z-1"></div>
    </template>
    <base-panel title="انتخاب زمین" :rightPanel="false" class="text-center">
      <template #default>
        <vue-element-loading
          :active="farmsNameListLoadingState"
          text=""
          spinner="bar-fade-scale"
          color="var(--secondary)"
        />
        <farms-name-list
          :farmsNameList="farmsSource"
          :farmersNameList="farmsSource"
          :displayReturnButton="false"
          :selectFarmLoading="selectFarmLoading"
          @selectFarm="selectFarm"
        ></farms-name-list>
      </template>
    </base-panel>
    <transition name="fade">
      <base-panel
        :rightPanel="true"
        v-if="selectedFarm"
        title="مشخصات زمین"
      >
        <template #default>
          <farm-info
            :farmInfo="selectedFarm"
            :area="selectedFarmArea"
          ></farm-info>
        </template>
      </base-panel>
    </transition>
    <VueModal
      :title="tensionDetail.title"
      v-model="tensionDetailModal"
      wrapper-class="animate__animated animate__faster"
      in-class="animate__fadeIn"
      out-class="animate__fadeOut"
      bg-class="animate__animated"
      :bg-in-class="`animate__fadeInDown`"
      :bg-out-class="`animate__fadeOutDown`"
    >
      <vue-element-loading
        :active="loading"
        text="دریافت جزئیات تنش"
        spinner="bar-fade-scale"
        color="var(--secondary)"
      />
      <CRow>
        <img :src="getImage(tensionDetail.image)" class="tensionDetailImage"/>
        <p class="tensionDetailDesc">
          {{ tensionDetail.description }}
        </p>
      </CRow>
    </VueModal>
  </main-body-section>
</template>

<script>
import {mapActions} from "vuex";

import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import {ZoomControl} from "mapbox-gl-controls";
import * as turf from "@turf/turf";
import {calculateBboxService} from "../../services/calculateBbox.service";
import polyGonStructure from "../../store/data/polygonFeature.json";
import {formatPolygonService} from "../../services/formatPolygon.service";

import "vue-select/dist/vue-select.css";
import * as moment from "jalali-moment";
import {apiUrlRoot} from "../../constants/config";
import MainBodySection from "../../components/mainBodySection.vue";
import FarmsNameList from "../../components/map/FarmsNameList.vue";
import BasePanel from "../../components/map/BasePanel.vue";
import FarmInfo from "../../components/map/FarmInfo.vue";

export default {
  name: "TensionForm",
  components: {
    FarmInfo,
    BasePanel, FarmsNameList,
    MainBodySection,
  },
  data() {
    return {
      map: null,
      loading: false,
      countryData: [],
      tensionDetailModal: false,
      farmsNameListLoadingState: false,
      selectFarmLoading: false,
      deleteConfirm: false,
      deleteMessage: "خطا در دریافت اطلاعات سطر",
      deleteId: 0,
      farmView: "",
      selectedFarmArea: null,
      farmSource: [],
      polygonCenter: null,
      formatedPolygon: null,
      farmSelected: "",
      farmsSource: [],
      cultiavtionType: ["دیم", "آبی", "دیم -آبیاری تکمیلی"],
      selectedFarm: "",
      tensionDetail: {
        title: "",
        image: "",
        description: "",
      },
    };
  },
  watch: {
    farmSelected: function () {
      this.selectFarm(this.farmSelected);
    },
  },
  methods: {
    ...mapActions("farmTension", ["GetAll", "Filter", "GetDetail"]),
    ...mapActions("farm", ["GetsTextValue", "Get"]),

    addPolygonLayer(polygon) {
      let geojason = {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [polygon],
        },
      };
      // Add a data source containing GeoJSON data.
      if (this.map.getSource("maine")) {
        if (this.map.getLayer("maine")) {
          this.map.removeLayer("maine");
          // this.map.removeLayer("outline");
        }
        this.map.removeSource("maine");
      }
      if (this.map.getSource("indexImage")) {
        if (this.map.getLayer("indexImage-layer")) {
          this.map.removeLayer("indexImage-layer");
        }
        this.map.removeSource("indexImage");
      }
      this.map.addSource("maine", {
        type: "geojson",
        data: geojason,
      });

      // Add a new layer to visualize the polygon.
      this.map.addLayer({
        id: "maine",
        type: "fill",
        source: "maine", // reference the data source
        layout: {},
        paint: {
          "fill-color": "#0080ff", // blue color fill
          "fill-opacity": 0.5,
        },
      });
    },
    loadFarmMap(cordinates) {
      polyGonStructure.features[0].geometry.coordinates = [cordinates];
      let farmArea = turf.area(polyGonStructure.features[0].geometry);
      this.selectedFarmArea = Intl.NumberFormat("fa").format(
        (farmArea / 10000).toFixed(2)
      );
      var polygon = turf.polygon([cordinates]);
      let center = turf.centerOfMass(polygon);
      this.polygonCenter = center["geometry"]["coordinates"];
      this.formatedPolygon = formatPolygonService.polygonreFormat(cordinates);
      let box = calculateBboxService.calculateBox(cordinates);
      this.addPolygonLayer(cordinates);
      this.map.fitBounds(box, {padding: 100});
      this.map.on("load", () => {
      });
    },
    async selectFarm(item) {
      this.selectFarmLoading = true;
      var result = await this.Get({id: item.value});
      this.kashtDatePersian = moment(result.cultivationsDate)
        .locale("fa")
        .format("YYYY-MM-D");
      result.kashtDatePersian = this.kashtDatePersian;
      this.selectedFarm = result;
      this.loadFarmMap(this.selectedFarm.cordinates);
      this.selectFarmLoading = false;
    },
    async fetchData() {
      var result = await this.GetAll({pageNumber: 0, pageSize: 0});
      if (result)
        result.forEach((value) => {
          new mapboxgl.Marker()
            .setPopup(
              new mapboxgl.Popup()
                .setHTML(`<div style="cursor: pointer;width: 220px; height: 130px;" onClick="showModal(${value.id})">
              <img src="${apiUrlRoot}/TensionImage/${value.tensionImg}" style="width: 100%;height: 100px;float: right;clear: both;" />
              <div>
              <h5 style="float: right;clear: both;width: 100%;color: blueviolet;text-align: right;margin-top: 5px;background-color: #bbb;padding: 4px;">${value.type}</h5>
              </div>
            </div>`)
            )
            .setLngLat([value.lat, value.lng])
            .addTo(this.map);
        });
    },
    async GetAllFarms() {
      this.farmsNameListLoadingState = true;
      this.farmsSource = await this.GetsTextValue({
        farmerType: null,
        farmerId: null,
      });
      this.farmsNameListLoadingState = false;
    },
    getImage(image) {
      return `${apiUrlRoot}/TensionImage/${image}`;
    },
    async showModal(id) {
      this.loading = true;
      this.tensionDetailModal = true;
      let result = await this.GetDetail({id: id});
      if (result) {
        this.tensionDetail.title = result.title;
        this.tensionDetail.image = result.image;
        this.tensionDetail.description = result.description;
        this.loading = false;
      } else {
        this.$notify({
          title: "خطا رخ داده است",
          text: "خطا در دریافت جزئیات تنش <br /><br />",
          type: "error",
        });
        this.loading = false;
        this.tensionDetailModal = false;
      }
    },
  },
  mounted() {
    mapboxgl.accessToken =
      "pk.eyJ1IjoiYmlvY2hpYXBwIiwiYSI6ImNsMzhiOHdyaTA1OXYzZG13aGI5bXc0Y2YifQ.KK2ncJJG5hJWECxJ9jQwCA";

    if (mapboxgl.getRTLTextPluginStatus() === "unavailable") {
      mapboxgl.setRTLTextPlugin(
        "https://api.mapbox.com/mapbox-gl-js/plugins/mapbox-gl-rtl-text/v0.2.3/mapbox-gl-rtl-text.js",
        null,
        true
      );
    }
    this.map = new mapboxgl.Map({
      container: "map",
      style: "mapbox://styles/biochiapp/cl4chu50q000314qsi4ng5wj1",
      //center: [59.55679711007316, 36.316138357670575], // starting position [lng, lat]
      center: [53.12609431323281, 33.59332186046887], // starting position [lng, lat]
      zoom: 5,
      showZoom: true,
      attributionControl: false,
      preserveDrawingBuffer: true,
    });
    this.map.addControl(new ZoomControl(), "bottom-left");
    this.map.on("load", () => {
    });
    this.map.on("draw.create", this.addPolygonLayer);
    this.fetchData();
    this.GetAllFarms();
  },
  created() {
    window.showModal = this.showModal;
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.tension-page .ads-wrapper img {
  width: 22rem !important;
}
</style>
<style scoped>

.tensionDetailImage {
  width: 100%;
  height: 150px;
  float: right;
  clear: both;
}

.tensionDetailDesc {
  width: 100%;
  padding: 5px;
  color: #f80;
  margin-top: 5px;
  box-shadow: 0 1px 4px 1px #000;
}
</style>
